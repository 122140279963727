/* -- MAIN NAVIGATION - MINI / COLLAPSED MODE -- */
/* ---------------------------------------------- */


@include media-breakpoint-up($mainnav-min-available-at) {
	.mn--min {
		padding: 0 !important;



		// Navigation mini mode.
		// ----------------------------------------------
		.mainnav {
			max-width: $mainnav-min-width;
			transition: max-width $mainnav-min-transition $mainnav-min-transition-timing;
			will-change: max-width;
            z-index: 1;
		}





		// Set all of the header components to mini mode.
		// ----------------------------------------------
		.header__inner {
			padding-left: 0;
		}


		.header__brand {
			justify-content: center;
			max-width: $mainnav-min-width;
			padding-inline-start: 0;
			transition: max-width $mainnav-min-transition $mainnav-min-transition-timing;
            width: 100%;
			will-change: max-width;
		}



        // Sticky header
		&.hd--sticky .mainnav__inner {
			top: $header-height;
            z-index: 9; // Prevent overlapping with listgroup active

			@at-root {
				.mn--sticky#{&} {
					height: calc( 100vh - #{$header-height} );
				}
			}
		}





		// Profile widget for the navigation.
		// ----------------------------------------------
		.mainnav__profile .mininav-content {
			min-width: 13rem;
		}


		.mainnav__avatar {
			height: auto;
			width: 2rem;
		}





		// Menu
		// ----------------------------------------------
		.mainnav__menu {

			// Hide all the components when the current navigation mode is in Mini.
			@at-root {
				.mn--min .d-mn-min{
                    display: block;
                }
				.mn--min .d-mn-max,
				.mn--min .d-mn-max:not(.show),
				.mn--min .brand-title,
				.mn--min .mainnav__caption,
				#{&} > .has-sub > .nav-link:after,
				#{&} > .nav-item > .nav-link .nav-label:not(.show) {
					display: none;
				}
			}


			> .nav-item {


				> .nav-link {
					justify-content: center;

                    i, .nav-icon {
                        color: $mainnav-min-menu-icon-color !important;
                    }

					&.active {
						background-color: $mainnav-min-menu-active-bg;
						color: $mainnav-min-menu-active-color;

                        i, .nav-icon {
                            color: $mainnav-min-menu-active-icon-color !important;
                        }
					}
				}
				&, & > .nav-link i { margin: 0 !important }

                & {
                    margin-bottom: .25rem !important;
                    width: 100%;
                }
			}


			> .has-sub .has-sub > .nav-link { gap: 2.5rem }
		}





        // Submenu togglers
        // ----------------------------------------------
		.mininav-toggle {
			cursor: pointer;

			.nav-label {
				color: $mainnav-color;
				&:hover {
					color: $mainnav-menu-hover-color;
				}
			}
		}





		// Collapsed submenus and hidden contents
		// ----------------------------------------------
		.mininav-content {
			padding: .5rem .25rem;
			background-color: $mainnav-bg;
			border-radius: $mainnav-submenu-border-radius;
			box-shadow: $mainnav-min-shadow;
			min-width: 11rem;
			max-width: 19rem;
			white-space: nowrap;
            margin-inline-start: Max( $content-gutter-x, #{map-get( $content-gutters-x, $mainnav-min-available-at ) - $nav-link-padding-x} ) !important;
			.nav-link {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}


			&.show {
				z-index: 100;
                transition: transform .15s linear .1s;
			}


			&:not(.nav) {
				padding: .75rem .25rem;
            }

            &.nav-label {
				padding: .75rem #{ .5rem + $nav-link-padding-x };
			}


			&.collapsing {
				transition: 0s !important;
			}
		}


   		.mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content {
			margin-inline-start: .25rem !important;
		}
	}
}