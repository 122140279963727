.model_metrics_analytics_card{
    margin-top: 17px;
    height: 10em;
    box-shadow: 0.5px 0.5px 0.5px 1px #888888;
    border-radius: 8px;
}

.model_metrics_analytics_big_card{
    margin-top: 17px;
    height: 20em;
    box-shadow: 0.5px 0.5px 0.5px 1px #888888;
    border-radius: 8px;
}


.model_metrics_analytics_card_upper_txt {
    padding: 1em;
    font-weight: 500;
    color: #555252;
}
.model_metrics_analytics_card_num_right{
    font-size: 2em;
    color: black;
    font-weight: 500;
    margin-top: 3px;
}