/* -- SEARCHBOX -- */
/* ---------------------------------------------- */
.searchbox {
  width: auto;
}

.searchbox .searchbox__input {
  padding-right: calc( 1ex + 2rem);
  transition: width 0.35s 0.25s ease, background-color 0.35s 0.25s ease;
}

.searchbox .searchbox__input:focus {
  transition: width 0.35s 0.25s ease, background-color 0.35s 0.25s ease;
}

.searchbox .searchbox__input.bg-transparent {
  background-color: transparent !important;
  border: 0;
  box-shadow: none !important;
  color: inherit;
}

.searchbox .searchbox__input.bg-transparent:focus {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.searchbox.input-group:not(.has-validation) > .searchbox__input:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: 0.4375rem;
}

.searchbox .searchbox__btn {
  font-size: 1rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.35s 0.25s ease, visibility 0.35s 0.25s ease;
  z-index: 5;
}

.searchbox.searchbox--auto-expand .searchbox__input {
  width: 20vw;
}

.searchbox.searchbox--auto-expand .searchbox__input:focus {
  width: 35vw;
}

.searchbox.searchbox--hide-btn .searchbox__btn {
  opacity: 0;
  visibility: hidden;
}

.searchbox.searchbox--hide-btn .searchbox__input:focus ~ .searchbox__btn, .searchbox.searchbox--hide-btn .searchbox__input:focus ~ div > .searchbox__btn {
  opacity: 1;
  transition: opacity 0.35s ease, visibility 0.35s ease;
  visibility: visible;
}

/* -- SCROLL PAGE TO TOP -- */
/* ---------------------------------------------- */
.scroll-container {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: Min(calc( 100% - 15.75rem), calc( 100vh + 15.75rem));
}

.scroll-page {
  align-items: center;
  background-color: #25476a;
  border: 4px solid #fff !important;
  border-radius: 0.4375rem;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  pointer-events: visible;
  position: sticky;
  right: 1rem;
  text-decoration: none;
  top: calc( 100vh - 4em - 1rem);
  width: 4em;
  z-index: 99999;
}

.scroll-page:hover {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
}

.scroll-page:after {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 0.75em;
  left: calc( 50% - .375em);
  pointer-events: none;
  position: absolute;
  top: calc( 50% - .175em);
  transform-origin: center center;
  transform: rotate(-45deg);
  width: 0.75em;
}

/* -- BACKDROP FOR MAIN NAVIGATION AND SIDEBAR -- */
/* ---------------------------------------------- */
.root:before {
  background-color: rgba(0, 0, 0, 0.55);
  content: "";
  display: block;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  z-index: -1;
}

.root.mn--show:before, .root.sb--show:before {
  cursor: pointer;
  opacity: 1;
  pointer-events: visible;
  visibility: visible;
  z-index: 9998;
}

/* -- TIMELINE -- */
/* ---------------------------------------------- */
.timeline {
  display: flex;
  flex-direction: column;
  gap: var(--timeline-entry-gap);
}

.timeline {
  --timeline-dot: var(--bs-primary);
  --timeline-line: var(--bs-primary);
  --timeline-line-width: 2px;
  --timeline-point-gap: 0.25rem;
  --timeline-point-size: 0.5rem;
  --timeline-media-size: 2.25rem;
  --timeline-entry-gap: 1.5rem;
}

.timeline .tl-time:not(:empty) {
  min-width: 7rem;
  text-align: end;
}

.timeline .tl-time .tl-date {
  font-size: 90%;
}

.timeline .tl-time .tl-time {
  color: var(--ton-headings-color);
  font-weight: 700;
}

.timeline .tl-entry {
  display: flex;
  gap: 0.75rem;
}

.timeline .tl-point:before {
  background-color: var(--timeline-dot);
  border-radius: 50%;
  content: "";
  display: block;
  height: var(--timeline-point-size);
  transform: translateY(0.75ex);
  width: var(--timeline-point-size);
}

.timeline .tl-point:after {
  border-inline-start: var(--timeline-line-width) solid var(--timeline-line);
  content: "";
  display: block;
  height: calc( (100% - var( --timeline-point-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap ));
  inset: calc( 0.75ex + var(--timeline-point-gap)) auto auto calc( 50% - ( var( --timeline-line-width ) / 2 ));
  position: relative;
}

.timeline .tl-media {
  flex: 0 0 var(--timeline-media-size);
}

.timeline .tl-media i {
  align-items: center;
  display: flex;
  justify-content: center;
}

.timeline .tl-media:after {
  border-inline-start: var(--timeline-line-width) solid var(--timeline-line);
  content: "";
  display: block;
  height: calc( (100% - var( --timeline-media-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap ));
  inset: var(--timeline-point-gap) auto auto calc( 50% - ( var( --timeline-line-width ) / 2 ));
  position: relative;
}

.timeline .tl-entry.active .tl-icon .ratio {
  box-shadow: 0 0 0 var(--timeline-line-width), 0 0 0 calc( var( --timeline-line-width ) * 2) var(--bs-primary);
}

.timeline .tl-entry.active .tl-point:before {
  background-color: transparent;
  box-shadow: 0 0 0 var(--timeline-line-width) var(--timeline-dot);
}

.timeline .tl-entry.active .tl-point:after {
  height: calc( (100% - var( --timeline-line-width ) - var( --timeline-point-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap ));
  top: calc( 0.75ex + var( --timeline-point-gap ) + var( --timeline-line-width ));
}

.timeline .tl-entry:last-child .tl-point:after, .timeline .tl-entry:last-child .tl-media:after {
  border-color: var(--ton-muted-color);
  border-inline-start-style: dotted;
}

.timeline-two-column .tl-entry > .tl-time, .timeline-two-column .tl-content {
  width: 50%;
}

.timeline-two-column .tl-content > * {
  display: inline-flex;
  flex-direction: column;
}

.timeline-two-column .tl-entry-start {
  flex-direction: row-reverse;
}

.timeline-two-column .tl-entry-start .tl-time {
  text-align: start;
}

.timeline-two-column .tl-entry-start .tl-content {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  justify-content: end;
}

/* -- SPEECH BUBBLE -- */
/* ---------------------------------------------- */
.bubble {
  background-color: var(--ton-body-bg);
  border-radius: 1.5rem;
  border-end-start-radius: 0;
  color: var(--ton-body-color);
  display: inline-flex;
  flex-direction: column;
  max-width: Min(400px, 80%);
  padding: 0.5rem 1rem;
  position: relative;
}

.bubble:after {
  border-color: transparent transparent var(--ton-body-bg) transparent;
  border-style: solid;
  border-width: 0 0 5px 7px;
  bottom: 0;
  content: "";
  display: block;
  left: -7px;
  position: absolute;
  width: 0;
  z-index: 1;
}

.bubble.bubble-primary, .bubble-primary .bubble {
  background-color: var(--ton-comp-active-bg);
  border-radius: 1.5rem;
  border-end-end-radius: 0;
  color: var(--ton-comp-active-color);
}

.bubble.bubble-primary:after, .bubble-primary .bubble:after {
  border-color: transparent transparent transparent var(--ton-comp-active-bg);
  border-width: 5px 0 0 7px;
  left: auto;
  right: -7px;
}

/* -- ROOT -- */
/* ---------------------------------------------- */
.root {
  display: grid;
  gap: 0 0;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  min-height: 100vh;
  width: 100%;
}

:root {
  --ton-body-bg: #fff;
  --ton-body-color: #7a878e;
  --ton-content-bg: #f7f8f9;
  --ton-content-color: #7a878e;
  --ton-headings-color: #2b2c2d;
  --ton-muted-color: #aeb0b2;
  --ton-link-color: #03a9f4;
  --ton-comp-active-bg: #25476a;
  --ton-comp-active-color: #fff;
  --ton-border-color: #f0f1f2;
  --ton-body-bg-rgb: 255, 255, 255;
  --ton-body-color-rgb: 122, 135, 142;
  --ton-content-bg-rgb: 247, 248, 249;
  --ton-content-color-rgb: 122, 135, 142;
  --ton-headings-color-rgb: 43, 44, 45;
  --ton-muted-color-rgb: 174, 176, 178;
  --ton-link-color-rgb: 3, 169, 244;
  --ton-comp-active-bg-rgb: 37, 71, 106;
  --ton-comp-active-color-rgb: 255, 255, 255;
  --ton-border-color-rgb: 240, 241, 242;
  --ton-border-radius: 0.4375rem;
}

html, body {
  scrollbar-color: #92a3b5 #fff0;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 0.4375rem;
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background: #92a3b5;
  border-radius: 2rem;
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
  background: #92a3b5;
  width: 0.625rem;
}

body {
  overflow-x: hidden;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
}

/* -- REBOOT -- */
/* ---------------------------------------------- */
hr, .vr {
  background-color: #f0f1f2;
}

.card hr, .vr {
  background-color: rgba(0, 0, 0, 0.15);
}

/* -- FRONT CONTAINER -- */
/* ---------------------------------------------- */
.front-container {
  background-color: #f7f8f9;
  color: #7a878e;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
}

.front-container .header {
  grid-area: 1 / 1 / 2 / 2;
}

.front-container .content {
  background-color: transparent;
  color: inherit;
  grid-area: 2 / 1 / 3 / 2;
}

.front-container .footer {
  grid-area: 3 / 1 / 4 / 2;
}

body[style*="background"]:not(.boxed-layout) > .front-container, .bg-img:not(.boxed-layout) > .front-container {
  background-color: transparent;
}

/* -- BUTTONS -- */
/* ---------------------------------------------- */
.content {
  background-color: #f7f8f9;
  color: #7a878e;
  display: flex;
  flex-direction: column;
  grid-area: 2 / 2 / 3 / 3;
  width: 100%;
  min-width: 0;
  max-width: 100vw;
}

.content .page-title {
  color: #25476a;
  font-size: 2.75rem;
  font-weight: 400;
  text-transform: capitalize;
}

.content__wrap {
  padding: 2rem 1rem;
}

.root:not(.hd--expanded) .content__header > .content__wrap {
  padding-bottom: 0 !important;
}

@media (min-width: 768px) {
  .content__wrap {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 992px) {
  .content__wrap {
    padding-inline: 1.45rem;
  }
}

@media (min-width: 1200px) {
  .content__wrap {
    padding-inline: 1.75rem;
  }
}

/* -- CONTENT VIEW -- */
/* ---------------------------------------------- */
.content-full-page {
  position: fixed;
  inset: 0;
  z-index: 999999;
}

/* -- HEADER -- */
/* ---------------------------------------------- */
.header {
  background-color: #25476a;
  color: #bec8d2;
  grid-area: 1 / 1 / 2 / 3;
}

.header__inner {
  align-items: center;
  display: flex;
  height: 4.375rem;
  padding-inline: 1rem;
}

.header__brand {
  align-items: center;
  display: flex;
  height: 100%;
  background-color: rgb(232, 14, 14) !important;

}

.brand-wrap {
  align-items: center;
  display: flex;
  position: relative;
}

.brand-wrap:hover {
  transition: transform 0.35s ease;
  transform: scale(1.1);
}

.brand-img {
  margin-right: 0.75rem;
  width: 40px;
}

.brand-img img {
  width: 100%;
}

.brand-title {
  display: none;
}

.header__content {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: space-between;
}

.header__content-start {
  flex-grow: 1;
}

.header__content-start, .header__content-end {
  align-items: center;
  display: flex;
}

.header__content-start > *, .header__content-end > * {
  margin: 0 .1rem;
}

.header__btn {
  border-radius: 0.875rem;
  box-shadow: none !important;
  color: inherit;
  font-size: .95rem;
}

.header__btn:hover, .header__btn:focus {
  background-color: rgba(0, 0, 0, 0.15);
  color: #e1e3e5;
}

.header-searchbox {
  position: relative;
  margin-left: auto;
}

.searchbox__backdrop {
  position: relative;
}

.hd--sticky .header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.hd--sticky.mn--sticky.mn--max .mainnav .mainnav__inner {
  height: calc( 100vh - 4.375rem);
  top: 4.375rem;
}

@media (max-width: 767.98px) {
  .header__content .searchbox {
    background-color: #25476a;
    left: 0;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
  }
  .header__content .searchbox .searchbox__input {
    position: fixed;
    top: -99rem;
  }
  .header__content .searchbox .searchbox__input, .header__content .searchbox .searchbox__input:focus ~ .searchbox__backdrop {
    margin-bottom: calc( 2px + 0.9875rem);
    margin-top: calc( 2px + 0.9875rem);
  }
  .header__content .searchbox .searchbox__input, .header__content .searchbox .searchbox__btn {
    transition-duration: 0s;
  }
  .header__content .searchbox .searchbox__input:focus {
    position: static;
  }
  .header__content .searchbox .searchbox__input:focus ~ .searchbox__backdrop:after {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  .header__content .dropdown-menu {
    margin-inline: .5rem !important;
    max-width: calc( 100vw - 1rem);
    width: 100vw;
  }
  .hd--sticky .header__content .dropdown-menu {
    max-height: calc( 100vh - 4.375rem - .5rem);
    overflow-x: hidden;
  }
}

@media (min-width: 768px) {
  .brand-img, .header-searchbox {
    margin: 0;
  }
  .brand-title {
    color: #fff !important;
    display: block;
    font-family: "Ubuntu", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0 0.75rem;
    text-decoration: none !important;
  }
  .searchbox__backdrop {
    height: 100%;
    margin: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (min-width: 992px) {
  .mn--max .header__brand {
    transition: max-width 0.35s ease;
    width: 100%;
    
  }
}

@media (min-width: 768px) {
  .header__inner {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 992px) {
  .header__inner {
    padding-inline: 1.45rem;
  }
}

@media (min-width: 1200px) {
  .header__inner {
    padding-inline: 1.75rem;
  }
}

/* -- EXPANDED HEADER -- */
/* ---------------------------------------------- */
.hd--expanded .content__header {
  background-color: #25476a;
  color: #a0afbd;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.hd--expanded .content__header :where(h1, h2, h3, h4, h5, h6) {
  color: #e4e7e9;
}

.hd--expanded .content__header .page-title {
  color: #eceeef;
}

.hd--expanded .content__header .page-title:after {
  background-color: #eceeef;
}

.hd--expanded .content__header.border-radius-start {
  border-bottom-left-radius: 0.65625rem;
}

.hd--expanded .content__header .breadcrumb-item.active {
  color: #a0afbd;
}

.hd--expanded .content__header .breadcrumb-item > a {
  color: #e4e7e9;
}

.hd--expanded .content__header .btn-primary {
  background-color: #1e3955;
  border-color: #1e3955;
}

.hd--expanded .content__header .btn-link {
  color: #c0eafc;
}

.hd--expanded .content__header .btn-link:hover, .hd--expanded .content__header .btn-link:focus, .hd--expanded .content__header .btn-link:active, .hd--expanded .content__header .btn-link.active {
  color: #74d0f9;
}

.hd--expanded .content__header.overlapping .content__wrap:after {
  content: "";
  display: block;
  height: 6.25rem;
  width: 100%;
}

.hd--expanded .content__header.overlapping + .content__boxed {
  margin-top: calc( -6.25rem - 2rem);
  z-index: 1;
}

.hd--expanded.mn--max.mn--sticky .content__header:before, .hd--expanded.mn--min.mn--sticky .content__header:before {
  position: sticky;
  top: -0.06125rem;
}

.hd--expanded.mn--max.hd--sticky .content__header:before, .hd--expanded.mn--min.hd--sticky .content__header:before {
  position: sticky;
  top: 4.375rem;
}

.hd--expanded.mn--max .content__header .content__wrap, .hd--expanded.mn--min .content__header .content__wrap {
  margin-top: -0.65625rem;
}

.hd--expanded.mn--max .content__header:before, .hd--expanded.mn--min .content__header:before {
  background-color: #25476a;
  content: "";
  display: block;
  height: 0.65625rem;
  margin-left: 0;
  -webkit-mask-image: radial-gradient(circle at 0 0.65625rem, transparent 0, transparent 0.65625rem, black 0.65625rem);
  mask-image: radial-gradient(circle 0.65625rem at 0 0.65625rem, transparent 0, transparent 0.65625rem, black 0.65625rem);
  position: relative;
  top: -0.06125rem;
  transform: translateX(-0.65625rem);
  width: 0.7175rem;
  z-index: 10;
}

/* -- FAIR HEADER -- */
/* ---------------------------------------------- */
@media (min-width: 992px) {
  body:not(.centered-layout) .hd--fair.mn--max .header__brand, body:not(.centered-layout) .hd--fair.mn--min .header__brand {
    background-color: #fff;
  }
  body:not(.centered-layout) .hd--fair.mn--max .header__brand .brand-title, body:not(.centered-layout) .hd--fair.mn--min .header__brand .brand-title {
    color: #3f7ab6 !important;
  }
  body:not(.centered-layout) .hd--fair .content__header .content__wrap {
    margin-top: auto;
  }
  body:not(.centered-layout) .hd--fair .content__header:before {
    display: none;
  }
}

/* -- MAINNAV MENU LINK -- */
/* ---------------------------------------------- */
.mainnav__caption {
  color: #c3c9cc;
}

.mainnav__inner .mainnav__menu > .nav-item > .nav-link {
  display: flex;
}

.mainnav__inner .mainnav__menu > .nav-item > .nav-link.active {
  background-color: #25476a;
  border-radius: 0.4375rem;
  box-shadow: none;
  color: #fff;
  font-weight: bold;
}

.mainnav__inner .nav-link {
  background-color: transparent;
  color: #7a878e;
}

.mainnav__inner .nav-link.disabled {
  opacity: .4;
}

.mainnav__inner .nav-link:hover {
  background-color: transparent;
  color: #25476a;
}

.mainnav__inner .nav-link.active:not(.important) {
  background-color: transparent;
  color: #25476a;
}

.mainnav__inner .nav-link.active i, .mainnav__inner .nav-link.active .nav-icon {
  color: #fff !important;
}

.mainnav__inner .nav-link i, .mainnav__inner .nav-link .nav-icon {
  color: #c3c9cc !important;
}

.mainnav__menu .mininav-content {
  flex-direction: column;
  flex-wrap: nowrap;
}

.mainnav__menu .mininav-toggle {
  align-items: center;
  display: flex;
}

.mainnav__menu .mininav-toggle > .nav-label, .mainnav__menu .mininav-toggle .nav-icon, .mainnav__menu .mininav-toggle i {
  pointer-events: none;
}

.mainnav__menu .mininav-toggle .nav-label {
  display: inline-block;
}

.mainnav__menu .has-sub > .mininav-toggle:not(.has-badge):after {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.55em;
  margin-left: auto;
  transform: rotate(135deg);
  transition: transform 0.175s;
  width: 0.55em;
}

.mainnav__menu .has-sub > .mininav-toggle.collapsed:after {
  transform: rotate(45deg);
}

.root:not(.mn--min) .mainnav__menu .has-sub.open > .mininav-content {
  display: block;
  height: auto !important;
}

.root:not(.mn--min) .mainnav__menu .has-sub.open > .mininav-toggle:after {
  transition: none !important;
  transform: rotate(135deg) !important;
}

@media (max-width: 991.98px) {
  .root .mininav-content {
    position: static !important;
    transform: none !important;
  }
  .root .mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content {
    border-inline-start: 1px dashed rgba(0, 0, 0, 0.45);
    border-radius: 0.4375rem;
    margin-inline-start: calc( 0.25em + 1rem) !important;
    padding-inline-start: .5rem;
  }
  .root .mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content > .nav-item > .nav-link {
    padding-inline: .75em;
  }
  .root .mainnav__menu > .nav-item.has-sub > .mininav-content {
    border-inline-start: 1px dashed rgba(0, 0, 0, 0.45);
    margin-inline-start: 1.75em !important;
  }
  .root .mainnav__menu > .nav-item.has-sub > .mininav-content > .nav-item > .nav-link {
    padding-inline-start: calc( 0.1em + 1rem);
  }
}

@media (min-width: 992px) {
  .root:not(.mn--min) .mininav-content {
    position: static !important;
    transform: none !important;
  }
  .root:not(.mn--min) .mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content {
    border-inline-start: 1px dashed rgba(0, 0, 0, 0.45);
    margin-inline-start: calc( 0.25em + 1rem);
    padding-inline-start: .5rem;
  }
  .root:not(.mn--min) .mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content > .nav-item > .nav-link {
    padding-inline: .75em;
  }
  .root:not(.mn--min) .mainnav__menu > .nav-item.has-sub > .mininav-content {
    border-inline-start: 1px dashed rgba(0, 0, 0, 0.45);
    margin-inline-start: 1.75em;
  }
  .root:not(.mn--min) .mainnav__menu > .nav-item.has-sub > .mininav-content > .nav-item > .nav-link {
    padding-inline-start: calc( 0.1em + 1rem);
  }
}

/* -- MAIN NAVIGATION - MINI / COLLAPSED MODE -- */
/* ---------------------------------------------- */
@media (min-width: 992px) {
  .mn--min {
    padding: 0 !important;
  }
  .mn--min .mainnav {
    max-width: 70px;
    transition: max-width 0.35s ease;
    will-change: max-width;
    z-index: 1;
  }
  .mn--min .header__inner {
    padding-left: 0;
  }
  .mn--min .header__brand {
    justify-content: center;
    max-width: 70px;
    padding-inline-start: 0;
    transition: max-width 0.35s ease;
    width: 100%;
    will-change: max-width;
  }
  .mn--min.hd--sticky .mainnav__inner {
    top: 4.375rem;
    z-index: 9;
  }
  .mn--sticky.mn--min.hd--sticky .mainnav__inner {
    height: calc( 100vh - 4.375rem);
  }
  .mn--min .mainnav__profile .mininav-content {
    min-width: 13rem;
  }
  .mn--min .mainnav__avatar {
    height: auto;
    width: 2rem;
  }
  .mn--min .d-mn-min {
    display: block;
  }
  .mn--min .d-mn-max,
  .mn--min .d-mn-max:not(.show),
  .mn--min .brand-title,
  .mn--min .mainnav__caption,
  .mn--min .mainnav__menu > .has-sub > .nav-link:after,
  .mn--min .mainnav__menu > .nav-item > .nav-link .nav-label:not(.show) {
    display: none;
  }
  .mn--min .mainnav__menu > .nav-item > .nav-link {
    justify-content: center;
  }
  .mn--min .mainnav__menu > .nav-item > .nav-link i, .mn--min .mainnav__menu > .nav-item > .nav-link .nav-icon {
    color: #7a878e !important;
  }
  .mn--min .mainnav__menu > .nav-item > .nav-link.active {
    background-color: #25476a;
    color: #fff;
  }
  .mn--min .mainnav__menu > .nav-item > .nav-link.active i, .mn--min .mainnav__menu > .nav-item > .nav-link.active .nav-icon {
    color: #fff !important;
  }
  .mn--min .mainnav__menu > .nav-item, .mn--min .mainnav__menu > .nav-item > .nav-link i {
    margin: 0 !important;
  }
  .mn--min .mainnav__menu > .nav-item {
    margin-bottom: .25rem !important;
    width: 100%;
  }
  .mn--min .mainnav__menu > .has-sub .has-sub > .nav-link {
    gap: 2.5rem;
  }
  .mn--min .mininav-toggle {
    cursor: pointer;
  }
  .mn--min .mininav-toggle .nav-label {
    color: #7a878e;
  }
  .mn--min .mininav-toggle .nav-label:hover {
    color: #25476a;
  }
  .mn--min .mininav-content {
    padding: .5rem .25rem;
    background-color: #fff;
    border-radius: 0.4375rem;
    box-shadow: 0.25rem 0 1rem 0 rgba(0, 0, 0, 0.1);
    min-width: 11rem;
    max-width: 19rem;
    white-space: nowrap;
    margin-inline-start: Max(1rem, 0.45rem) !important;
  }
  .mn--min .mininav-content .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mn--min .mininav-content.show {
    z-index: 100;
    transition: transform .15s linear .1s;
  }
  .mn--min .mininav-content:not(.nav) {
    padding: .75rem .25rem;
  }
  .mn--min .mininav-content.nav-label {
    padding: 0.75rem 1.5rem;
  }
  .mn--min .mininav-content.collapsing {
    transition: 0s !important;
  }
  .mn--min .mainnav__menu > .nav-item.has-sub .has-sub > .mininav-content {
    margin-inline-start: .25rem !important;
  }
}

/* -- MAIN NAVIGATION - MAX / EXPANDED MODE -- */
/* ---------------------------------------------- */
@media (min-width: 992px) {
  .mn--max .mainnav {
    position: relative;
  }
  .mn--max .mainnav__inner {
    position: absolute;
  }
  .mn--max .mainnav {
    transition: max-width 0.35s ease;
    will-change: max-width;
  }
  .mn--max .header__inner {
    padding-inline-start: 0;
  }
  .mn--max .header__content, .mn--min .header__content {
    padding-inline-start: 1.45rem;
  }
  .mn--max .header__brand {
    padding-inline-start: 1.45rem;
    max-width: 13.75rem;
    width: 13.75rem;
    will-change: max-width;
  }
}

@media (min-width: 1200px) {
  .mn--max .header__brand {
    padding-inline-start: 1.75rem;
    background-color: rgb(232, 14, 14) !important;
  }
}

/* -- DEFAULT MAIN NAVIGATION -- */
/* ---------------------------------------------- */
.mainnav {
  display: flex;
  grid-area: 2 / 1 / 3 / 2;
  max-width: 13.75rem;
  width: 13.75rem;
}

.mainnav__inner {
  background-color: #fff;
  color: #7a878e;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mainnav__inner :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6):not(.mainnav__caption) {
  color: #25476a;
}

.mainnav__inner :where(.border-top, .border-end, .border-bottom, .border-start) {
  border-color: #f0f1f2 !important;
}

.mainnav__inner .dropdown-toggle:after {
  color: #25476a;
}

.mainnav__inner .text-muted {
  color: #aeb0b2 !important;
}

.d-mn-max, .d-mn-max.collapse {
  display: block;
}

.d-mn-min {
  display: none;
}

.mainnav__top-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.mainnav__top-content.os-host-overflow {
  overflow: visible;
}

.mainnav__bottom-content {
  background-color: #fff;
  bottom: 0;
  margin-top: auto;
  position: sticky;
}

.mainnav__top-content, .mainnav__bottom-content {
  padding-inline: 1rem;
}

@media (min-width: 768px) {
  .mainnav__top-content, .mainnav__bottom-content {
    padding-inline: Max(1rem, 0.25rem);
  }
}

@media (min-width: 992px) {
  .mainnav__top-content, .mainnav__bottom-content {
    padding-inline: Max(1rem, 0.45rem);
  }
}

@media (min-width: 1200px) {
  .mainnav__top-content, .mainnav__bottom-content {
    padding-inline: Max(1rem, 0.75rem);
  }
}

.mn--show .mainnav {
  transform: translateX(0);
  z-index: 9999;
}

.mn--show .mainnav, .mainnav {
  transition: transform 0.35s ease;
}

.mn--sticky .mainnav__inner {
  height: 100vh;
  position: sticky;
  top: 0;
}

.root:not(.mn--max):not(.mn--min) .mainnav {
  position: absolute;
  inset: 0 auto;
}

.root:not(.mn--max):not(.mn--min):not(.mn--show) .mainnav {
  transform: translateX(-13.8125rem);
  z-index: 1002;
}

@media (max-width: 991.98px) {
  .mainnav__menu .mininav-toggle .nav-label {
    position: static !important;
    transform: none !important;
  }
  .mn--min .mainnav {
    inset: 0 auto;
    position: absolute;
  }
  .mn--min:not(.mn--show) .mainnav {
    transform: translateX(-13.8125rem);
    z-index: 1002;
  }
}

@media (max-width: 991.98px) {
  .mn--max .mainnav {
    inset: 0 auto;
    position: absolute;
  }
  .mn--max:not(.mn--show) .mainnav {
    transform: translateX(-13.8125rem);
    z-index: 1002;
  }
}

/* -- MAIN NAVIGATION - OFF CANVAS / PUSH MODE -- */
/* ---------------------------------------------- */
.mn--push .mainnav {
  transform: translateX(-13.8125rem);
}

.mn--push .mainnav, .mn--push .content, .mn--push .header, .mn--push.mn--show .mainnav, .mn--push.mn--show .content, .mn--push.mn--show .header {
  transition: transform 0.35s ease;
}

.mn--push.mn--show .mainnav {
  transform: translateX(0);
  z-index: 9999;
}

.mn--push.mn--show .content, .mn--push.mn--show .header {
  transform: translateX(13.75rem);
}

.mn--push.mn--show.sb--show.sb--pinned .sidebar {
  transform: translateX(13.75rem);
}

/* -- MAIN NAVIGATION - OFF CANVAS / REVEAL MODE -- */
/* ---------------------------------------------- */
.mn--reveal .mainnav {
  pointer-events: none;
  transform: translateX(0) !important;
  z-index: 5 !important;
}

.mn--reveal .header {
  z-index: 12;
}

.mn--reveal .content, .mn--reveal .sidebar {
  z-index: 10;
}

.mn--reveal.sb--pinned .sidebar {
  z-index: 11;
}

.mn--reveal .content, .mn--reveal .header, .mn--reveal.mn--show .content, .mn--reveal.mn--show .header {
  transition: transform 0.35s ease;
}

.mn--reveal.mn--show .mainnav {
  pointer-events: visible;
}

.mn--reveal.mn--show:before, .mn--reveal.mn--show .content, .mn--reveal.mn--show .header {
  transform: translateX(13.75rem);
}

.mn--reveal.mn--show:before {
  transition: opacity 0.35s, transform 0.35s !important;
}

.mn--reveal.mn--show.sb--show.sb--pinned .sidebar {
  transform: translateX(13.75rem);
}

/* -- SIDEBARS -- */
/* ---------------------------------------------- */
.sidebar {
  align-items: stretch;
  background-color: #fff;
  box-sizing: content-box;
  color: #7a878e;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(17.1875rem);
  visibility: hidden;
  width: 0;
  z-index: 1002;
}

.sidebar__inner {
  height: 100vh;
  overflow-x: hidden;
  padding-block: 1.5rem;
  position: sticky;
  top: 0;
}

.sidebar__wrap {
  padding-inline: 1rem;
}

.sb--show .sidebar {
  overflow: visible;
  transform: translateX(0);
  visibility: visible;
  width: 17.1875rem;
  z-index: 9999;
}

.sidebar {
  transition: visibility 0.35s, width 0s linear 0.35s, padding 0.35s ease, transform 0.35s ease;
}

.sb--show .sidebar {
  transition: visibility 0.35s, padding 0.35s ease, transform 0.35s ease;
}

.sidebar__stuck {
  display: none;
}

@media (min-width: 768px) {
  .sidebar__wrap {
    padding-inline: Max(1rem, 0.25rem);
  }
}

@media (min-width: 992px) {
  .sidebar__wrap {
    padding-inline: Max(1rem, 0.45rem);
  }
}

@media (min-width: 1200px) {
  .sidebar__wrap {
    padding-inline: Max(1rem, 0.75rem);
  }
}

/* -- DISABLE THE SIDEBAR BACKDROP -- */
/* ---------------------------------------------- */
.sb--bd-0.sb--show:before {
  opacity: 0;
}

.sb--bd-0.sb--show .sidebar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

/* -- SIDEBAR - STATIC POSITION */
/* ---------------------------------------------- */
.sb--static .sidebar__inner {
  overflow: visible;
  position: static;
}

/* -- SIDEBAR - STUCK -- */
/* ---------------------------------------------- */
.sidebar__stuck {
  display: none;
}

.sb--stuck.sb--show:before {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.sb--stuck.sb--show .sidebar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.sb--stuck .sidebar__stuck {
  display: flex;
}

/* -- SIDEBAR UNITE -- */
/* ---------------------------------------------- */
.sb--unite .sidebar {
  background-color: #25476a;
  color: #a2aab3;
}

.sb--unite .sidebar :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6), .sb--unite .sidebar .nav-link.active, .sb--unite .sidebar .nav-link:hover, .sb--unite .sidebar .nav-link:focus {
  color: #f6f7f7;
}

.sb--unite .sidebar .text-muted {
  color: rgba(162, 170, 179, 0.75) !important;
}

.sb--unite .sidebar .btn-primary {
  background-color: #1e3955;
  border-color: #1e3955;
}

.sb--unite .sidebar .btn-link {
  color: #c0eafc;
}

.sb--unite .sidebar .btn-link:hover, .sb--unite .sidebar .btn-link:focus, .sb--unite .sidebar .btn-link:active, .sb--unite .sidebar .btn-link.active {
  color: #74d0f9;
}

.sb--unite .sidebar .nav-callout {
  border-color: rgba(255, 255, 255, 0.1);
}

.sb--unite .sidebar .nav-callout .nav-link.active {
  box-shadow: inset 0 -0.1875rem #f6f7f7;
}

.sb--unite .sidebar .nav-link {
  color: #a2aab3;
}

.sb--unite .sidebar .nav-link i, .sb--unite .sidebar .nav-link .nav-icon {
  color: rgba(162, 170, 179, 0.65) !important;
}

.sb--unite .sidebar .list-group-item-action:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

/* -- PINNED SIDEBAR */
/* ---------------------------------------------- */
@media (min-width: 1024px) {
  .sb--pinned:not(.mn--push):not(.mn--reveal) .sidebar {
    transition-duration: 0s !important;
  }
  .sb--pinned:not(.sb--unite) .sidebar {
    background-color: transparent;
  }
  .sb--pinned:not(.sb--unite) .sidebar .sidebar__inner {
    background-color: #f7f8f9;
    color: #7a878e;
  }
  .sb--pinned:not(.sb--static) .sidebar .sidebar__inner {
    height: calc( 100vh - 4.375rem);
    overflow-x: hidden;
  }
  .sb--pinned .sidebar {
    height: calc( 100% - 4.375rem);
    top: 4.375rem;
    z-index: 999;
  }
  .sb--pinned .sidebar .sidebar__inner {
    border-top-left-radius: 0.65625rem;
  }
  .sb--pinned.sb--show:not(.mn--show):before {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .sb--pinned.sb--show.hd--sticky .sidebar__inner {
    top: 4.375rem;
  }
  .sb--pinned.sb--show .content__boxed {
    padding-right: 17.1875rem;
  }
  .sb--pinned.sb--unite .sidebar__inner {
    border-radius: 0;
  }
  .sb--pinned.sb--unite .sidebar:after {
    background-color: #25476a;
    content: "";
    display: block;
    height: 0.65625rem;
    margin-left: -0.65625rem;
    margin-top: -100vh;
    -webkit-mask-image: radial-gradient(circle at 0 0.65625rem, transparent 0, transparent 0.65625rem, black 0.71875rem);
    mask-image: radial-gradient(circle at 0 0.65625rem, transparent 0, transparent 0.65625rem, black 0.71875rem);
    position: relative;
    top: 4.375rem;
    width: 0.7175rem;
  }
  .sb--pinned.sb--unite.hd--sticky .sidebar:after {
    position: sticky;
  }
  .sb--pinned.sb--unite.hd--expanded.sb--show .content__header:after {
    align-self: flex-end;
    background-color: #25476a;
    content: "";
    display: block;
    height: 0.71875rem;
    -webkit-mask-image: radial-gradient(circle at 0 0.65625rem, transparent 0.65625rem, black 0.71875rem);
    mask-image: radial-gradient(circle at 0 0.65625rem, transparent 0.65625rem, black 0.71875rem);
    position: absolute;
    top: 100%;
    transition: transform 0.35s ease;
    width: 0.71875rem;
    z-index: 1000;
  }
}

@media (min-width: 980px) {
  .centered-layout .sb--pinned:not(.sb--unite) .sidebar__inner {
    border-radius: 0.65625rem;
  }
  .centered-layout .sb--pinned.sb--show .content__header {
    padding-inline-end: calc( (( 100vw - 980px ) / 2) + 17.1875rem) !important;
    width: auto !important;
  }
  .centered-layout .sb--pinned.sb--show .content__boxed {
    padding-inline-end: calc(  17.1875rem + 1.45rem + .3125rem);
  }
  .centered-layout .sb--pinned.sb--show .sidebar {
    padding-right: calc( ((100vw - 980px) / 2));
  }
  .centered-layout .mn--min.sb--pinned.sb--show .content__boxed, .centered-layout .mn--max.sb--pinned.sb--show .content__boxed {
    padding-inline-end: calc( ( ((100vw - 980px) / 2 ) + 17.1875rem ));
  }
  .centered-layout .mn--min.sb--pinned.sb--show .sidebar, .centered-layout .mn--max.sb--pinned.sb--show .sidebar {
    padding-right: calc( ((100vw - 980px) / 2));
  }
}

/* -- TRANSITION TIMING FUNCTIONS -- */
/* ---------------------------------------------- */
.in-quart .mn--reveal.mn--show:before,
.in-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.755, 0.045, 0.915, 0.135) !important;
}

.out-quart .mn--reveal.mn--show:before,
.out-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.015, 0.77, 0.04, 0.985) !important;
}

.in-back .mn--reveal.mn--show:before,
.in-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.705, -0.155, 0.735, 0.045) !important;
}

.out-back .mn--reveal.mn--show:before,
.out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.28, 1.02, 0.36, 1.145) !important;
}

.in-out-back .mn--reveal.mn--show:before,
.in-out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(1, -0.28, 0, 1.275) !important;
}

.steps .mn--reveal.mn--show:before,
.steps :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0, 0.955, 1, 0.045) !important;
}

.jumping .mn--reveal.mn--show:before,
.jumping :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.005, 1.045, 0.25, 0.915) !important;
}

.rubber .mn--reveal.mn--show:before,
.rubber :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.135, 1.525, 0, 0.9) !important;
}

/* -- SCROLLBARS -- */
/* ---------------------------------------------- */
.scrollable-content {
  scrollbar-color: #92a3b5 #fff0;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollable-content::-webkit-scrollbar {
  width: 0.4375rem;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: #92a3b5;
  border-radius: 2rem;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #92a3b5;
  width: 0.625rem;
}

/* -- OPTIMIZE -- */
/* ---------------------------------------------- */
.offcanvas-body,
.dropdown-menu,
.sidebar {
  content-visibility: auto;
}

.dropdown-menu img[loading="lazy"], .offcanvas img[loading="lazy"], .sidebar img[loading="lazy"] {
  display: none;
}

.dropdown-menu.show img[loading="lazy"], .offcanvas.show img[loading="lazy"], .sb--show .sidebar img[loading="lazy"] {
  display: unset;
}

/* -- COLORS -- */
/* ---------------------------------------------- */
.bg-gray-100 {
  background-color: #f7f8f9;
}

.bg-gray-200 {
  background-color: #f0f1f2;
}

.bg-gray-300 {
  background-color: #e8eaec;
}

.bg-gray-400 {
  background-color: #e1e3e5;
}

.bg-gray-500 {
  background-color: #d9dcdf;
}

.bg-gray-600 {
  background-color: #aeb0b2;
}

.bg-gray-700 {
  background-color: #828486;
}

.bg-gray-800 {
  background-color: #575859;
}

.bg-gray-900 {
  background-color: #2b2c2d;
}

.bg-blue {
  background-color: #03a9f4;
}

.text-blue {
  color: #03a9f4;
}

.bg-indigo {
  background-color: #25476a;
}

.text-indigo {
  color: #25476a;
}

.bg-purple {
  background-color: #ab47bc;
}

.text-purple {
  color: #ab47bc;
}

.bg-pink {
  background-color: #f06292;
}

.text-pink {
  color: #f06292;
}

.bg-red {
  background-color: #df5645;
}

.text-red {
  color: #df5645;
}

.bg-orange {
  background-color: #ffb300;
}

.text-orange {
  color: #ffb300;
}

.bg-yellow {
  background-color: #ffe405;
}

.text-yellow {
  color: #ffe405;
}

.bg-green {
  background-color: #8bc34a;
}

.text-green {
  color: #8bc34a;
}

.bg-teal {
  background-color: #26a69a;
}

.text-teal {
  color: #26a69a;
}

.bg-cyan {
  background-color: #0dcaf0;
}

.text-cyan {
  color: #0dcaf0;
}

.bg-white {
  background-color: #fff;
}

.text-white {
  color: #fff;
}

.bg-gray {
  background-color: #aeb0b2;
}

.text-gray {
  color: #aeb0b2;
}

.bg-gray-dark {
  background-color: #575859;
}

.text-gray-dark {
  color: #575859;
}

/* -- OBJECT FIT -- */
/* ---------------------------------------------- */
.object-fill {
  object-fit: fill;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

/* -- MEDIA PRINT -- */
/* ---------------------------------------------- */
@page {
  margin: 0cm auto;
  size: 8.5in 11in;
}

@media print {
  html, body, #root, .content {
    background-color: transparent;
  }
  #root {
    display: block;
  }
  .card {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .header, .sidebar, #mainnav-container,
  .content__header, footer, .scroll-container, #_dm-settingsToggler {
    display: none !important;
  }
  .hd--expanded .content__header.overlapping + .content__boxed {
    margin-top: 0 !important;
  }
}

/* -- RIBBON -- */
/* ---------------------------------------------- */
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #E53F50;
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #e53f50;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #e53f50;
}

.ribbon span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #e53f50;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #e53f50;
}

/* -- ERROR CODE -- */
/* ---------------------------------------------- */
.error-code {
  font-size: 9rem !important;
  font-weight: 400;
  line-height: 1;
}

/* -- BOXED LAYOUT -- */
/* ---------------------------------------------- */
@media (min-width: Max(1024px, 1200px)) {
  .boxed-layout {
    background-attachment: fixed;
    background-color: #828486;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 1rem;
    padding-inline-end: 0 !important;
  }
  .boxed-layout .root {
    -webkit-clip-path: inset(0 0 0 0 round 0.75rem);
    clip-path: inset(0 0 0 0 round 0.75rem);
  }
  .boxed-layout .mn--reveal.mn--show:before {
    max-width: 1200px;
    margin: 0 auto;
    transition: transform 0.35s ease !important;
    transform: translateX(calc( (100% - 1200px ) + 13.75rem));
  }
  .boxed-layout .mega-dropdown {
    max-width: calc( 75 * 1200px / 100);
  }
  .boxed-layout .searchbox.searchbox--auto-expand .searchbox__input {
    max-width: 30vw;
  }
  .boxed-layout .sb--pinned .sidebar {
    top: 5.4375rem;
  }
}

/* -- CENTERED LAYOUT -- */
/* ---------------------------------------------- */
@media (min-width: 980px) {
  .centered-layout .hd--expanded:not(.mn--max):not(.mn--min) .content__header {
    box-sizing: content-box;
    margin: 0;
    padding-inline: calc( (( 100% - 980px ) / 2 ) - 1.75rem);
    width: calc( 980px + 3.5rem);
  }
  .centered-layout .content__boxed, .centered-layout .header__inner, .centered-layout .footer {
    margin: 0 auto;
    min-width: calc( 980px + 3.5rem);
    max-width: calc( 980px + 3.5rem);
  }
  .centered-layout .mn--max .content__boxed, .centered-layout .mn--max .header__inner {
    max-width: none;
    margin: 0 auto;
  }
  .centered-layout .mn--min .header__inner {
    padding-inline-start: 1.75rem;
  }
  .centered-layout .mn--min .header__brand {
    width: auto;
    max-width: none;
    justify-content: start;
  }
  .centered-layout .mn--min .brand-title {
    display: block;
  }
  .centered-layout .mn--min .content__boxed {
    margin: 0;
    max-width: none;
    padding-left: calc( (( 100vw - 980px ) / 2 ) - 70px - 1.75rem);
    padding-right: calc( (( 100vw - 980px ) / 2 ) - 1.75rem);
  }
  .centered-layout .mn--min .content__boxed:not(.content__header) {
    min-width: 100%;
    max-width: min-content;
  }
  .centered-layout .mn--min .content__header:before {
    transform: translateX(Min(-0.59375rem, calc( ((( 980px - 100vw ) / 2 ) + 70px + 1.75rem) - 0.65625rem)));
  }
}

@media (min-width: 1200px) {
  .centered-layout .mn--max .content__boxed, .centered-layout .mn--max .header__inner {
    margin: 0 auto;
    max-width: calc( 980px + 3.5rem);
  }
  .centered-layout .mn--max .header__brand {
    width: auto;
  }
  .centered-layout .mn--max .content__boxed {
    margin: 0;
    max-width: none;
    padding-left: calc( (( 100vw - 980px) / 2) - 13.75rem - 1.75rem);
    padding-right: calc( (( 100vw - 980px) / 2 ) - 1.75rem);
  }
  .centered-layout .mn--max .content__boxed:not(.content__header) {
    min-width: 100%;
    max-width: min-content;
  }
  .centered-layout .mn--max .content__header:before {
    transform: translateX(Min(-0.59375rem, calc( ((( 980px - 100vw ) / 2 ) + 13.75rem + 1rem))));
  }
}


