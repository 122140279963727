/* -- MEDIA PRINT -- */
/* ---------------------------------------------- */

@page {
    margin: 0cm auto;
    size: 8.5in 11in;
}

@media print {
    html, body, #root, .content {
        background-color: transparent;
    }


    #root {
        display: block;
    }


    .card {
        background-color: transparent !important;
        box-shadow: none !important;
    }


    .header, .sidebar, #mainnav-container,
    .content__header, footer, .scroll-container, #_dm-settingsToggler {
        display: none !important;
    }


    .hd--expanded .content__header.overlapping + .content__boxed {
        margin-top: 0 !important;
    }
}