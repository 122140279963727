
#legend {
    /* width: 200px; */
    /* height: 450px; */
    /* border: 1px solid #eee; */
    /* margin-left: 10px; */
    /* float: left; */
  }
  
  #legend .legend-item {
    margin: 10px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    color: black;
  }
  
  #legend .legend-item .legend-value {
    font-size: 12px;
    font-weight: normal;
    margin-left: 22px;
  }
  
  #legend .legend-item .legend-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid #ccc;
    margin-right: 3px;
    margin-left: 20px;
  }
  
  #legend .legend-item.disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
  }

  .ga_draggable_components{
    height :auto;
  }