/* -- BUTTONS -- */
/* ---------------------------------------------- */


// Button variants loops
// ----------------------------------------------
@each $color, $value in $theme-colors {
    .btn-#{ $color } {
        box-shadow: 0 0 0 $value, 0 0 0 rgba( $black, .25 );

        &:hover { box-shadow: 0 .1rem .5rem rgba( $value, .5 ), 0 .25rem 1rem rgba( $black, .2 ) }
        &:active { box-shadow: 0 0 .5rem $value, 0 0 .75rem rgba( $black, 0.2 ) !important }
        &:focus, &:active:focus { box-shadow: 0 0 0 1px $value, 0 0 .7rem .25rem rgba( $value, .4 ) !important }
    }
}





// Buttons Extra Small
// ----------------------------------------------
.btn-xs {
    @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $border-radius-sm);
}





// Preventing Child from firing from click event
// ----------------------------------------------
.btn {
    i, .icon {
        user-select: none;
        pointer-events: none;
    }
}





// Icon for toggle buttons
// ----------------------------------------------
.btn {
    &[aria-pressed="true"]{
        .icon-active {
            display: unset;
        }
        .icon-inactive {
            display: none;
        }
    }

    &[aria-pressed="false"]{
        .icon-active {
            display: none;
        }
        .icon-inactive {
            display: unset;
        }
    }
}





// Fix Bootstrap's outline button light color.
// ----------------------------------------------
.btn-outline-light {
    &, &:disabled, &.disabled {
        color: $body-color;
    }
}





// Buttons Icon
// ----------------------------------------------
.btn-icon {
    align-items: center;
    box-sizing: content-box;
    display: inline-flex;
    height: calc( #{ $input-btn-padding-y * 2 } + #{ $line-height-base + "em" } );
    justify-content: center;
    padding: 0;
    width: calc( #{ $input-btn-padding-y * 2 } + #{ $line-height-base + "em" } );


    &.btn-lg {
        width: calc( #{ $input-btn-padding-y-lg * 2 } + #{ $line-height-base + "em" } );
        height: calc( #{ $input-btn-padding-y-lg * 2 } + #{ $line-height-base + "em" } );
    }


    &.btn-sm {
        width: calc( #{ $input-btn-padding-y-sm * 2 } + #{ $line-height-base + "em" } );
        height: calc( #{ $input-btn-padding-y-sm * 2 } + #{ $line-height-base + "em" } );
    }


    &.btn-xs {
        width: calc( #{ $input-btn-padding-y-sm * 2 } + .75em );
        height: calc( #{ $input-btn-padding-y-sm * 2 } + .75em );
    }
}





// Disable transition for button links
// ----------------------------------------------
.btn-link {
    transition-duration: 0s;
}





// Button with transparent background
// ----------------------------------------------
.btn.bg-transparent:not(.shadow-none) {
    &:hover { box-shadow: none }
    &:active { box-shadow: 0 0 .75rem rgba( $black, 0.2 ) !important }
    &:focus { box-shadow: 0 0 .7rem rgba( $black, .2 ) !important }
}





// Borderline button when hovering.
// ----------------------------------------------
.btn-underlined {
    transition-property: color, background-color, border-color, box-shadow, border-radius;
    &:hover {
        color: var( --bs-primary ) !important;
        box-shadow: 0 3px 0 0 var( --bs-primary ) !important;
    }

    &:hover:not(:focus):not(:active), &.shadow-none:focus, &.shadow-none:active {
        border-radius: 0;
    }
}





// Btn Hover
// ----------------------------------------------
.btn-hover:not(:hover):not(:focus):not(:active):not(.active) {
    background-color: transparent !important;
    border-color: transparent !important;
    color: var( --ton-headings-color );
}