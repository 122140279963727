.div-loading {
    position: fixed;
    z-index: 15000;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5) !important;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.div-loading-inner-div {
    margin-left: 52%;
    margin-top: 25%;
}

.div-loading-inner {
    width: 115px;
    height: 115px;
    background-color: transparent;
    position: absolute;
    /* display: none; */
    margin-left: -50px;
    margin-top: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* optional, center the image */
}

.div-loading-mid {
    width: 120px;
    height: 122px;
    background-color: transparent;
    position: absolute;
    /* display: none; */
    margin-left: -50px;
    margin-top: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* optional, center the image */
    -webkit-animation: spinner 4000ms infinite linear;
    -moz-animation: spinner 4000ms infinite linear;
    -ms-animation: spinner 4000ms infinite linear;
    -o-animation: spinner 4000ms infinite linear;
    animation: spinner 4000ms infinite linear;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rdt_TableHeadRow>div:nth-of-type(1) {
    background-color: #f9fafb;
    border-top-left-radius: 10px;
}

@media screen and (max-width: 1500px) {
    .mobile_survey_details {
        color: red;
    }

    .dashboard-create-new-call-button {
        margin-top: 24px;
        // float:right;
        margin-left: -15px;
    }

}

@media screen and (min-width: 1200px) {
    .filter_div {
        height: 93px;
    }

    .dashboard-create-new-call-button {
        margin-top: 24px;
        float: right;
        // margin-right: 15px;
    }

}

@media screen and (max-width: 1200px) {
    .filter_div {
        height: 163px !important;
    }

    .dashboard-create-new-call-button {
        margin-top: 24px;
        float: right;
        margin-right: 15px;
    }

}

@media screen and (max-width: 600px) {
    .filter_div {
        height: 275px !important;
    }

    .dashboard-create-new-call-button {
        margin-top: 24px;
        float: right;
        margin-right: 10px;

    }
}

.p-dialog-header-icon:last-child:focus {

    outline: none !important;

    box-shadow: none !important;

}




.p-dialog .p-dialog-content {

    padding-bottom: 40px !important;

}




.p-dialog .p-dialog-header {

    padding-top: 40px !important;

}

.p-dialog-header-icons {

    margin-top: -30px;

}