/* index.tsx */
.security_lvl_bge_lvl1 {
  border-radius: 6px !important;
  background-color: #fffaeb !important;
  color: #992b0abf !important;
}

.security_lvl_bge_lvl1_sub {
  background-color: #fec84b !important;
}

.security_lvl_bge_lvl2 {
  border-radius: 6px !important;
  background-color: #fff4eb !important;
  color: #df9612 !important;
}

.security_lvl_bge_lvl2_sub {
  background-color: #df9612 !important;
}

.security_lvl_bge_lvl3 {
  border-radius: 6px !important;
  background-color: #fef3f2 !important;
  color: #992b0abf !important;
}

.security_lvl_bge_lvl3_sub {
  background-color: #fef3f2 !important;
}

.security_lvl_bge_lvl_nil {
  border-radius: 6px !important;
  background-color: #f0f1f2 !important;
  color: #0000007d !important;
}

.security_lvl_bge_lvl_normal {
  border-radius: 6px !important;
  background-color: #ecfdf3 !important;
  color: #0b643f !important;
}

.security_lvl_bge_lvl_sub_nil {
  background-color: #0000007d !important;
}

.security_lvl_bge_lvl_sub_normal {
  background-color: #12b76a !important;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  font-size: 15px;
}

.p-dialog-header {
  padding-bottom: 0px !important;
  padding-top: 5px !important;
}

.btn-primary:hover {
  background-color: #25476a !important;
}

/* index.tsx */

/* Slim Scroll Bar Starts */
/* .slimmScrolBar { */
/*   background: gray; */
/* width: 100% !important; */
/* max-height: 200px; */
/* overflow: auto; */
/* } */

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* Slim Scroll Bar Ends */

.custom_common_label {
  font-size: 13px;
  font-weight: 700;
}

.p-component {
  font-size: 13px !important;
}



.needs_verification {
  background-color: #EEDBD8 !important;
  color: #ec7a38 !important;
  border-radius: 4px !important;
}

.verified {
  background-color: #cff0f0 !important;
  color: #22b9b9 !important;
}

.AnalyzeBtnDashboard {
  border: 1px solid #ccc !important;
  border-radius: 8px;
  height: 32px;
  font-size: 12px !important;
  font-weight: 500;
  color: #3e4f58;



}

.AnalyzeBtnDashboard:hover {
  background-color: #f4f4f4 !important;
}

.dashboardDownloadIcon:hover {
  background-color: #f4f4f4 !important;
}

.DashboardFilterButton:hover {
  background-color: #f4f4f4 !important;
}

.DashboardSearchAssetInput:hover {
  border: 1px solid black !important;
}

.ValidateBtnDashboard {
  border-radius: 8px;
  border: 1px solid #fff !important;
  height: 32px;
  background-color: #25476a !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500;
}

.ValidateBtnDashboard:hover {
  /* background-color: #aedaee !important; */
}

/* .dashboardTableBorder{
  border: 1px solid red !important;
  border-radius: 8px !important;
  width: 95% !important;
  margin-left: 20px;
  margin-right: 20px;
} */

/*Jerin  Changes*/
.rdt_Pagination {
  width: 100% !important;
  border-radius: 10px;
  display: none !important;
}

.p-button:focus {
  outline: none;
  box-shadow: none;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: none;
  box-shadow: none !important;

}

/* .rdt_TableCol_Sortable {
  opacity: 1 !important;
}

.__rdt_custom_sort_icon__ {
  opacity: 1 !important;
} */

.p-button .p-button-icon-left {
  font-size: 9.5px;
  font-weight: 700;
}

.rdt_TableHead {
  /* border: 1px solid #ccc !important; */
  border-radius: 7px !important;
}

.rdt_TableHeadRow {
  /* border: 1px solid #ccc !important; */
  border-radius: 7px !important;
}

.rdt_TableBody {
  /* border: 1px solid #ccc !important; */
  border-radius: 7px !important;
}

.rdt_TableRow:first-of-type {
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.rdt_TableRow:last-of-type {
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.rdt_TableRow:last-of-type {
  border-bottom: 0px !important;
}

.rdt_Table {
  border: 1px solid #ccc !important;
  /* border-bottom: 0px solid #ccc !important; */
}

/* .rdt_Pagination {
  border-bottom: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
} */

.rdt_TableCol:last-of-type {
  border-top-right-radius: 7px !important;
  /* border-bottom-right-radius: 9px !important; */
}


.rdt_TableCol:first-of-type {
  border-top-left-radius: 7px !important;
  /* border-bottom-left-radius: 9px !important; */
}

/*For adjustment of data table fix in dashboard fix*/
.header {
  padding: 0px 0px 0px 0px !important;
}

.gasSlugBadge {
  color: green;
  float: right;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.4375rem;
  border-radius: 6px !important;
  background-color: #ddeefa !important;
  color: #077ac9 !important;
}

.gasLockBadge {
  color: green;
  float: right;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.4375rem;
  border-radius: 6px !important;
  background-color: #eaf5f4 !important;
  color: #84c6bc !important
}

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--blue-500);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.asset_name_prefilled_validate_popup {
  background-color: white !important;
}

.pi-sort-alt:before {
  content: '\e903 \A \e902';
  font-size: 10px;
  font-weight: 700;
}

.pi-sort-amount-up-alt:before {
  content: '\e903';
  font-size: 10px;
  font-weight: 700;

}

.pi-sort-amount-down:before {
  content: '\e902';
  font-size: 10px;
  font-weight: 700;


}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0rem white !important;
  outline: 0 none !important;
}

.p-datatable-wrapper {
  border-bottom: 1px solid rgb(196, 196, 196);
}

.p-paginator-bottom {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.p-column-filter-buttonbar {
  display: none;
}

.float_eyes_toRight_raw {
  position: absolute;
  right: 0%;
  cursor: pointer;
}

.float_eyes_toRightviewOP {
  position: absolute;
  right: 6%;
  cursor: pointer;
}

.float_eyes_toRightviewOP_AI {
  position: absolute;
  right: 8%;
  cursor: pointer;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #dc4405;
  color: #25476a;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left !important;
  padding-right: 0.5rem !important;
  color: #ffffff !important;
  content: var(--bs-breadcrumb-divider, ">");
  font-weight: 500;
  /* rtl: var(--bs-breadcrumb-divider, ">") */
  ;
}

.p-multiselect-close {
  visibility: hidden;
}

.p-datatable-emptymessage td {
  text-align: center !important;
}
.content_background_color{
  background-color: #ecf0f5 !important;
}