/* -- BADGES -- */
/* ---------------------------------------------- */


// The element is aligned with the superscript baseline of the parent
// ----------------------------------------------
.badge-super {
    position: absolute !important;
    inset: 0 auto auto 100%;
    transform: translate( Max( -0.625rem, -50% ), -50% );
    z-index: 1;
}