/* -- DROPDOWN -- */
/* ---------------------------------------------- */


// Dropdown Menu
// ----------------------------------------------
.dropdown-menu {
    @if $dropdown-animations {

        $dto: $dropdown-transition-out;
        @if $dropdown-use-custom-js != true {
            $dto: 0s;
        }

        bottom: 0 !important;
        display: block;
        height: max-content;
        margin-bottom: $dropdown-start-gap;
        margin-top: $dropdown-start-gap;
        opacity: 0;
        pointer-events: none;
        top: 0 !important;
        touch-action: none;
        transition-timing-function: $dropdown-transition-timing-out;
        transition: visibility $dto, margin $dto ease-in-out, opacity $dto, transform 0s linear $dto;
        transform: scale(0);
        visibility: hidden;
    }



    // Show the dropdown menu
    &.show {
        box-shadow: $dropdown-box-shadow;

        @if $dropdown-animations {
            margin: 0 !important;
            opacity: 1;
            pointer-events: visible;
            touch-action: auto;
            transition-timing-function: $dropdown-transition-timing-in;
            transition: visibility $dropdown-transition-in, margin $dropdown-transition-in ease-in-out, opacity $dropdown-transition-in, transform 0s;
            visibility: visible;
        }
    }



    @if $dropdown-animations {

        // Top Placement
        &[data-popper-placement*="top-"] {
            top:auto !important;
        }


        // Dropleft and dropright
        @at-root {
            .dropend > #{&}, .dropstart > #{&} {
                margin-bottom: 0;
                margin-left: #{ 0 - $dropdown-start-gap };
                margin-right: #{ 0 - $dropdown-start-gap };
                margin-top: 0;
            }
        }
    }



    // Dropdown divider
    .dropdown-divider {
        background-color: $dropdown-divider-bg;
        margin-left: 0 - $dropdown-item-padding-x;
        margin-right: 0 - $dropdown-item-padding-x;
    }
}





// Block Header
// ----------------------------------------------
.dropdown-block-header {
    margin: #{ 0 - $dropdown-padding-y } #{ 0 - $dropdown-padding-x };
    border-start-start-radius: $dropdown-border-radius;
    border-start-end-radius: $dropdown-border-radius;
}





// Mega Dropdown
// ----------------------------------------------
.mega-dropdown {
    max-width: 100vw;
    width: max-content;
}



@include media-breakpoint-up( "lg" ) {
    .mega-dropdown {
        max-width: 75vw;
    }
}





// Rounded dropdown items
// ----------------------------------------------
.dropdown-item {
    border-radius: min( $dropdown-border-radius / 2, .45rem );
}





// Static dropdown
// ----------------------------------------------
.dropdown-menu[ data-bs-popper ], .mst {
    top: 100% !important;
}





// Dropdown With custom javascript
// ----------------------------------------------
@if $dropdown-use-custom-js {

    // Track the Dropdown Menu position before it closes by using custom js events.
    // ----------------------------------------------

    // Menu on the top.
    .mot {
        top: auto !important;
    }


    // Menu on the left.
    .mol {
        left: auto !important;
        right: 0 !important;
    }



    // Responsive menu alignment
    // ----------------------------------------------
    @each $breakpoint in map-keys( $grid-breakpoints ) {
        @include media-breakpoint-up( $breakpoint ) {
            $infix: breakpoint-infix( $breakpoint, $grid-breakpoints );
            @if $breakpoint != "xs" {
                .dropdown-menu#{ $infix }-start {
                    left: 0 !important;
                    right: auto !important;
                }


                .dropdown-menu#{ $infix }-end {
                    left: auto !important;
                    right: 0 !important;
                }
            }
        }
    }
}