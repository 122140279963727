/* -- BUTTONS -- */
/* ---------------------------------------------- */

// Button variants loops
// ----------------------------------------------
.content {
	background-color: $content-bg;
	color: $content-color;
    display: flex;
    flex-direction: column;
	grid-area: 2 / 2 / 3 / 3;
    width: 100%;
    min-width: 0;
    max-width: 100vw;

    .page-title {
        color: $content-title-color;
        font-size: 2.75rem;
        font-weight: 400;
        text-transform: capitalize;
    }
}



.content__wrap {
    padding: $content-gutter-y $content-gutter-x;
}



.root:not(.hd--expanded) .content__header > .content__wrap {
    padding-bottom: 0 !important;
}



// Content Gutters
// ----------------------------------------------
@each $breakpoint, $value in $content-gutters-x {
	@include media-breakpoint-up( $breakpoint ) {
		.content__wrap {
			padding-inline: $value;
		}
	}
}