/* -- TABLES -- */
/* ---------------------------------------------- */


.table {
	border-collapse: separate;
	border-radius: $table-border-radius;
	border-spacing: 0;
	overflow: hidden;


    --ton-table-color           : #{ $table-color };
    --ton-table-border-color    : #{ $table-border-color };


	// Table header ( bottom bottom width )
	// ----------------------------------------------
	&:not( .table-borderless ):not( .table-bordered ) > :not( :last-child ) > :last-child > * {
		border-bottom-width: 2px;
	}



	// Remove the bottom border on the last child.
	// ----------------------------------------------
	> :not( .caption ) > *:last-child > * {
		border-bottom-width: 0;
	}



	td, th {
		&:first-child {
			padding-left: 8px;
		}
		&:last-child {
			padding-right: 8px;
		}
	}



	// Disable thead additional border bottom
	// ----------------------------------------------
	tr:first-child {
		th, td {
			border-top: 0;
		}
	}
}





// Table Bordered
// ----------------------------------------------
.table-bordered {
    border-collapse: collapse;
}





// Table Separate
// ----------------------------------------------
.table-separate {
	border-spacing: 0 $table-separate-spacing;
	overflow: visible;


	td, th {
		background-color: rgba( $black, $table-separate-bg-factor );
		border: 0;



		&:first-child {
			border-top-left-radius: $table-border-radius;
			border-bottom-left-radius: $table-border-radius;
		}



		&:last-child {
			border-top-right-radius: $table-border-radius;
			border-bottom-right-radius: $table-border-radius;
		}
	}



	.bg-transparent {
		td, th {
			background-color: transparent !important;
			color: $table-color;
		}
	}
}





// Table Variants
// ----------------------------------------------
@mixin table-variant( $state, $background ) {
  	.table-#{ $state } {
		> :not( :last-child ) > :last-child > * {
			border-bottom-color: mix( #fff, $background, 20% );
		}

		&.table-separate {
			td, th {
				background-color: var( --#{$variable-prefix}table-bg );
			}
		}
  	}
}





@each $color, $value in $table-variants {
	@include table-variant( $color, $value );
}