/* -- TIMELINE -- */
/* ---------------------------------------------- */


.timeline {
    display: flex;
    flex-direction: column;
    gap: var( --timeline-entry-gap );





    & {
        --timeline-dot          : #{ $timeline-dot };
        --timeline-line         : #{ $timeline-line };
        --timeline-line-width   : #{ $timeline-line-width };
        --timeline-point-gap    : #{ $timeline-point-gap };
        --timeline-point-size   : #{ $timeline-point-size };
        --timeline-media-size   : #{ $timeline-media-size };
        --timeline-entry-gap    : #{ $timeline-entry-gap };
    }





    // Timeline Time
    // ----------------------------------------------
    .tl-time {
        &:not(:empty) {
            min-width: 7rem;
            text-align: end;
        }

        .tl-date {
            font-size: 90%;
        }

        .tl-time {
            color: var( --ton-headings-color );
            font-weight: 700;
        }
    }





    // Timeline entry
    // ----------------------------------------------
    .tl-entry {
        display: flex;
        gap: $timeline-gap;
    }





    // Timeline dot point
    // ----------------------------------------------
    .tl-point {
        &:before {
            background-color: var( --timeline-dot );
            border-radius: 50%;
            content: "";
            display: block;
            height: var( --timeline-point-size );
            transform:  translateY( $timeline-point-margin-top );
            width: var( --timeline-point-size );
        }

        &:after {
            border-inline-start: var( --timeline-line-width ) solid var( --timeline-line );
            content: "";
            display: block;
            height: calc( (100% - var( --timeline-point-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap )  );
            inset: calc( #{ $timeline-point-margin-top } + #{ var( --timeline-point-gap ) } ) auto auto calc( 50% - ( var( --timeline-line-width ) / 2 ) );
            position: relative;
        }
    }





    // Timeline media for icons and images
    // ----------------------------------------------
    .tl-media {
        flex: 0 0 var( --timeline-media-size );

        i {
            align-items: center;
            display: flex;
            justify-content: center;
        }


        &:after {
            border-inline-start: var( --timeline-line-width ) solid var( --timeline-line );
            content: "";
            display: block;
            height: calc( (100% - var( --timeline-media-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap )  );
            inset: #{ var( --timeline-point-gap ) } auto auto calc( 50% - ( var( --timeline-line-width ) / 2 ) );
            position: relative;
        }
    }



    // Active state
    // ----------------------------------------------
    .tl-entry.active {
        .tl-icon .ratio {
            box-shadow: 0 0 0 var( --timeline-line-width ),
            0 0 0 calc( var( --timeline-line-width ) * 2 ) $timeline-line;
        }


        .tl-point {
            &:before {
                background-color: transparent;
                box-shadow: 0 0 0 var( --timeline-line-width ) var( --timeline-dot );
            }

            &:after {
                height: calc( (100% - var( --timeline-line-width ) - var( --timeline-point-size ) - ( var( --timeline-point-gap ) * 2 )) + var( --timeline-entry-gap )  );
                top:  calc( #{ $timeline-point-margin-top } + var( --timeline-point-gap ) + var( --timeline-line-width ));
            }
        }
    }





    // Last entry with dashed border
    // ----------------------------------------------
    .tl-entry:last-child {
        .tl-point:after, .tl-media:after {
            border-color: var( --ton-muted-color );
            border-inline-start-style: dotted;
        }
    }

}






// Two column timeline
// ----------------------------------------------
.timeline-two-column {

    .tl-entry > .tl-time, .tl-content {
        width: 50%;
    }


    .tl-content > * {
        display: inline-flex;
        flex-direction: column;
    }


    .tl-entry-start {
        flex-direction: row-reverse;

        .tl-time {
            text-align: start;
        }

        .tl-content {
            align-items: flex-end;
            display: inline-flex;
            flex-direction: column;
            justify-content: end;
        }
    }
}
